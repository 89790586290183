import React from "react";
import SarSysConfiguration from "./_components/sarsysConfiguration";
import TeamSiteConfiguration from "./_components/teamSiteConfiguration";
import SarMapConfiguration from "./_components/sarMapConfiguration";
import LocateMeConfiguration from "./_components/locateMeConfiguration";
import TextingConfiguration from "./_components/textingConfiguration";
import CiemConfiguration from "./_components/ciemConfiguration";
import EvacConfiguration from "./_components/evacConfiguration";
// import KstatConfiguration from "./_components/kstatConfiguration";

const Systems = () => {
  return (
    <div>
      <h1 className={"m-2 pb-4 text-2xl"}>System Configurations</h1>
      <div className={"grid grid-cols-3"}>
        <div className={"pl-2 pr-2"}>
          <SarSysConfiguration/>
        </div>
        <div className={"pl-2 pr-2"}>
          <TeamSiteConfiguration/>
          <div className={"h-2"}></div>
          <SarMapConfiguration/>
          <div className={"h-2"}></div>
          <CiemConfiguration/>
        </div>
        <div className={"pl-2 pr-2"}>
          <EvacConfiguration/>
          <div className={"h-2"}></div>
          <LocateMeConfiguration/>
          <div className={"h-2"}></div>
          <TextingConfiguration/>
          {/*<KstatConfiguration />*/}
        </div>
      </div>
    </div>
  );
};
export default Systems;