import React, {useEffect, useState} from "react";
import {Organisation_Receiver} from "../../../../../context/organisation";
import CheckboxSlider from "../../../../../shared/components/CheckboxSlider";
import API from "../../../../../shared/API";
import Loader from "../../../../../shared/components/Loader";
import FieldSSOApplications from "./_fieldSSOApplication";

const EvacConfiguration = ({organisation}) => {
  const [isLoading, setIsLoading]       = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submitError, setSubmitError]   = useState("");
  const [isModified, setIsModified]     = useState(false);
  const [orgConfig, setOrgConfig]       = useState("");
  const [config, setConfig]             = useState({
    isEnabled: false,
    application: "",
    endpoint: ""
  });

  const handleSubmit = () => {
    setIsSubmitting(true);
    setSubmitError("");
    API.org.settings.systems.setConfig(organisation.data._id, "evac", config).then(() => {
      getData();
      organisation._refresh();
    }).catch(err => {
      setSubmitError(err.response.data.message);
    }).finally(() => {
      setIsSubmitting(false);
    });
  };
  const getData      = () => {
    API.org.settings.systems.getConfig(organisation.data._id, "evac").then(res => {
      setConfig(a => {
        const b = {
          ...a,
          ...res
        };
        setOrgConfig(JSON.stringify(b));
        return b;
      });
    }).catch(err => {

    }).finally(() => {
      setIsLoading(false);
    });
  };

  useEffect(() => {
    getData();
  }, []);
  useEffect(() => {
    if (JSON.stringify(config) !== orgConfig){
      setIsModified(true);
    } else {
      setIsModified(false);
    }
  }, [config]);

  if (isLoading){
    return (
      <Loader>Getting Evac Config...</Loader>
    );
  }
  return (
    <div className={`border ${isModified ? "border-yellow-800" : "border-green-800"} rounded-xl p-2`}>
      <h1 className={"text-xl"}>Evac</h1>
      {isSubmitting && <div className={"p-2 bg-blue-600"}>Saving changes...</div>}
      {submitError && <div className={"p-2 bg-red-600"}>{submitError}</div>}
      <CheckboxSlider title={"Enabled"} onChange={a => setConfig({...config, isEnabled: a})} isChecked={config.isEnabled} />

      {config.isEnabled && <div className={"my-2 p-2 border"}>
        <FieldSSOApplications
          systemType={"evac"}
          disabled={isSubmitting}
          application={config.application}
          endpoint={config.endpoint}
          onChange={data => {
            setConfig({
              ...config,
              application: data.application,
              endpoint: data.endpoint
            })
          }} />
      </div>}
      {isModified && <button disabled={isSubmitting} className="bg-green-600 text-white rounded-xl p-2 mt-4 float-right" onClick={() => handleSubmit()}>Save Changes</button>}
      <div className={"clear-both"}>{""}</div>
    </div>
  );
}
export default Organisation_Receiver(EvacConfiguration, (organisation) => {
  return {organisation};
});